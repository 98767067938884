
@import '../../../css/styles/colors';

.entry-login,
.entry-create-account{
  height: 100%;
}

.center-div-outer .center-div-middle > .entry:first-child{
  padding: 0px;
  transition: padding 0.3s;
}

.entry{
  margin: 60px 0;
  .carousel-header{
    margin: 0px;
  }
  .entry-header{
    padding: 20px 0;
    color: rgba(0,0,0,0);
    height: 88px;
    display: flex;
    justify-content: center;
    svg{
      height: 100%;
      .real-path{
        fill: $primary-color;
      }
    }
    .logo-text{
      line-height: 48px;
      display: block;
      color: $primary-color;
      font-size: 24px;
      font-weight: 700;
      font-style: italic;
      padding: 0 12px;
    }
  }  
  .url-check{
    border: 1px solid black;
  }
  .sns-button-space{
    height: 18px;
  }
  .recaptcha{
    max-width: 200px;
  }
  .sign-in-slide,
  .sign-up-slide{
    padding: 20px 20px 0;
  }
  .entry-sns{
    padding: 0 20px 20px;
  }
}

@media screen and (max-width: 342px) {
  .center-div-outer .center-div-middle > .entry:first-child{
    padding: 20px 8px;
  }
}