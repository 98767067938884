@import '../../../css/styles/variables';

.help-comp{
  max-width: 820px;
  margin: 0 auto;
  padding: $default-component-padding;  

  .block{
    margin: 0 0 40px;
    // &:last-of-type{margin-bottom: 0px;}

    &.self-service{
      .items{
        display: flex;
        flex-wrap: wrap;
        padding: 0 20px 20px 0;
        .item{
          margin: 20px 0px 0px 20px;
          padding: 20px;
          display: block;
          // background-color: rgba(240,240,240,1);
          background-color: #FFF;
          // box-shadow: 0px 1px 3px 0px rgba(0,0,0,.8);
          border-radius: 3px;
          // &:active{
            // box-shadow: inset 0px 1px 3px 0px rgba(0,0,0,.8);
          // }
        }
      }
    }
    &.faq{
      .items{
        display: flex;
        flex-flow: column;
        .item{
          margin: 0 0 40px;
          &:last-of-type{margin-bottom: 0px;}
          .question{
            margin: 0 0 8px;
            font-weight: 500;
          }
          .answer{

          }

        }
      }
    }
  }
}