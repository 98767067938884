@import '../../css/styles/colors';
@import '../../css/styles/variables';

.navi-footer{
  margin: 20px 0 12px;
  #account-notifs .badge{
    text-align: center;
    width: 14px;
    height: 14px;
    line-height: 14px;
    font-size: 12px;
    top: 2px;
    right: 2px;
    position: absolute;
    background-color: red;
    border-radius: 50%;
    color: #FFF;
    box-shadow: 0px 1px 3px 0px rgba(0,0,0,.5);
  }
  .navi-footer-button{
    border-radius: 5px;
    padding: 0 4px;
    margin: 8px;
    display: flex;
    height: 44px;
    cursor: pointer;
    .icon{
      height: 100%;
      width: 32px;
      padding: 4px;
      position: relative;
      svg,img{
        height: 100%;
        width: 100%;
        .real-path{
          fill: #FFF;
        }
      }
    }
    .text{
      margin: 0 4px;
      line-height: 44px;
      color: #FFF;
    }
    &:hover{
      transition: all 0.3s;
      background-color: #FFF;
      .icon svg .real-path{
        fill: $primary-color;
      }
      .text{
        color: $primary-color;
      }
    }
  }
  .notifications-window{
    box-shadow: -1px 0px 3px 0px rgba(0,0,0,.1);
    top: 48px;
    right: -280px;
    width: 280px;
    height: calc(100vh - 48px);
    position: fixed;
    z-index: 525;
    background-color: rgba(241,242,245,1);
    transition: right 0.3s cubic-bezier(.7,.3,.1,1), opacity 0.3s cubic-bezier(.7,.3,.1,1);
    opacity: 0;
    .close-button{
      background-color: inherit;
      box-shadow: -1px 1px 3px 0px rgba(0,0,0,.1);
      border-radius: 3px 0px 0px 3px;
      top: 0px;
      left: 0px;
      position: absolute;
      z-index: 30;
      height: 48px;
      width: 48px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      transition: left 0.3s;
      svg{
        height: 100%;
        width: 100%;
        padding: 10px;
      }
      &:hover svg{box-shadow: inset 0px 0px 250px 0px rgba(0,0,0,.1);}
      &:active svg{box-shadow: inset 0px 0px 250px 0px rgba(0,0,0,.2);}
    }
    &.show{
      opacity: 1;
      right: 0px;
      .close-button{
        left: -48px;
      }
    }
  }
}
@media screen and (min-width: $desktop-width) {
  .navi-main.toggle-width-active{
    .navi-footer{
      .navi-footer-button{
        justify-content: center;
        align-items: center;
        .text{
          position: absolute;
          left: 60px;
          opacity: 0;
          transition: left 0.3s,opacity 0.3s;
          border-radius: 3px;
          padding: 6px 12px;
          height: 44px;
          width: auto;
          padding: 0;
          width: 0px;
          height: 0px;
          z-index: 100;
          background-color: $primary-color;
          color: #FFF;
          box-shadow: 0px 1px 3px 0px rgba(0,0,0,.2);
          white-space: nowrap;
          overflow: hidden;
        }
        &:hover{
          .text{
            overflow: visible;
            left: 66px;
            opacity: 1;
            display: flex;
            padding: 0px 12px;
            line-height: 32px;
            height: 32px;
            width: auto;
          }
        }
      }
    }
  }
  
}

.navi-footer .account-related{display: none;}
.navi-footer .account-menu{display: none;}
@media screen and (min-width: $desktop-width) {
  .navi-main.top-navi .navi-footer{
    .navi-footer-button{display:none;}
    margin: 0;
    .account-related{
      display: flex;
      margin: 0 4px;
      
      .block{
        position: relative;
        margin: 0 4px;
        display: flex;
        color: #FFF;
        padding: 0 0px 0 36px;
        height: 36px;
        align-items: center;
        border-radius: 3px;
        .icon{
          position: absolute;
          left: 0;
          top: 0;
          height: 100%;
          width: 36px;
          padding: 6px;
          svg,img{
            height: 100%;
            width: 100%;
            .real-path{
              fill: #FFF;
              transition: fill 0.3s;
            }
          }
          img{
            border-radius: 50%;
          }
        }
        .text{
          padding: 0 8px 0 4px;
        }
        &:hover,
        &.active{
          text-decoration: none;
          cursor: pointer;
          background-color: #FFF;
          color: $primary-color;
          .icon svg .real-path{
            fill: $primary-color;
          }
        }
      }
    }
    .account-menu{
      &:not(.hide){
        display: block;
      }
      top: 48px+8px;
      padding: 4px 0;
      position: fixed;
      z-index: 100;
      background-color: #FFF;
      box-shadow: 3px 3px 8px 0px rgba(0,0,0,.25);
      border-radius: 3px;
      .block{
        display: flex;
        align-items: center;
        height: 40px;
        margin: 0 0 4px;
        &:last-of-type{margin:0;}
        .icon{
          padding: 6px;
          height: 100%;
          width: 40px;
          svg,img{
            height: 100%;
            width: 100%;
            .real-path{
              fill: $primary-color;
              transition: fill 0.3s;
            }
          }
          img{
            border-radius: 50%;
          }
        }
        .text{
          flex: 1;
          padding: 0 16px 0 4px;
          color: $primary-color;
          .display{
            font-size: 16px;
          }
          .desc{
            font-size: 10px;
          }
        }
        &:hover{
          cursor: pointer;
          background-color: $primary-color;
          color: #FFF;
          text-decoration: none;
          .icon svg .real-path{
            fill: #FFF;
          }
          .text{
            color: #FFF;
          }
        }
      }
    }
  }
}

.sandbox .navi-footer-button:hover{
  .icon svg .real-path{ fill: $sandbox-primary-color;}
  .text{ color: $sandbox-primary-color;}
}

.sandbox .navi-main.top-navi .navi-footer {
  .account-related .block{
    &:hover,
    &.active{
      background-color: #FFF;
      color: $sandbox-primary-color;
      .icon svg .real-path{
        fill: $sandbox-primary-color;
      }
    }
  }
  .account-menu .block{
    .icon svg .real-path{ fill: $sandbox-primary-color;}
    .text{color: $sandbox-primary-color;}
    &:hover{
      background-color: $sandbox-primary-color;
      .icon svg .real-path{ fill: #FFF;}
      .text{ color: #FFF;}
    }
  }
}
 