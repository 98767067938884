@import '../../../css/styles/colors';
@import '../../../css/styles/variables';


.logo-c{
  color: $primary-color;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Kanit", sans-serif;
  font-weight: 800;
  font-size: 22px;
  // font-size: 24px;
  img{
    margin-right: 4px;
    padding: 4px;
    max-height: 40px;
    max-width: 40px;
  }

  .text{
    .one{
      margin: 0px 6px 0px 0px;
    }
  }
  
  

  .adbegone{display: none;}
  &.small{
    // @media screen and (max-width: $desktop-width) {
      .text{
        display: flex;
        flex-flow: column;
      }
    // }
    .text{
      // font-size: 16px;
      // line-height: 16px;
    }
  }

  .icon{
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    max-height: 48px;
    max-width: 48px;
    margin-right: 4px;
    svg{
      transform: scale(1.05);
      height: 100%;
      width: 100%;      
      background-color: #FFF;
      .bg-path{
        fill: $primary-color;
      }
    }
  }
  
  @media screen and (min-width: $desktop-width) {
    &.tiny-not-mobile{
      font-size: 11px;
      flex-wrap: wrap;
      line-height: 11px;
    }
  }
  &.large{
    font-size: 32px;
    img{
      max-height: 52px;
      max-width: 52px;
    }
  }
  
}
.sandbox{
  .logo-c{ 
    color: $sandbox-primary-color; 
    .icon svg .bg-path{fill: $sandbox-primary-color;}
  }
}
.logo-c{
  &.white{
    color: #FFF;
  }
}