.test-socket{
  margin: 20px auto 40px;
  max-width: 800px;


  .chat-box{
    background-color: #FFF;
    box-shadow: 0px 3px 8px 0px rgba(0,0,0,.1);
    height: 50vh;
    border: 2px solid black;
    border-radius: 8px;
    display: flex;
    .messages{
      display: flex;
      flex-flow: column;
      flex: 7;
      .label{
        padding: 12px;
        border: 1px solid black;
        border-width: 0px 0px 2px 0px;
      }
      .messages-list{
        overflow: auto;
        padding: 12px;


        flex: 1;
        .chat-bubble{
          display: flex;
          flex-flow: column;
          margin: 0 25% 0 0;
          margin-bottom: 4px;
          .text{
            font-size: 14px;
            background-color: rgba(0,0,0,.1);
            padding: 4px 8px;
            border-radius: 5px;
          }
          .client{
            opacity: 0.7;
            font-size: 10px;
          }
          &.active-user{
            margin: 0 0 0 25%;
            margin-bottom: 4px;

            .text{
              background-color: rgba(0,125,255,.3);
            }
          }
        }
      }
      .input-box{
        border: 2px solid black;
        border-width: 2px 0 0 0;
        padding: 12px;
        display: flex;
        textarea{
          margin: 0px;
        }
        button{
          margin-left: 12px;
          width: 120px;
          text-align: center;
        }
      }
    }
    .users{
      border: 2px solid black;
      border-width: 0px 0px 0px 2px;
      flex: 3;
      display: flex;
      flex-flow: column;
      .label{
        padding: 12px;
        border: 1px solid black;
        border-width: 0px 0px 2px 0px;
      }
      .users-list{
        overflow: auto;
        flex: 1;
        padding: 12px;
        .active{
          background-color: rgba(0,255,125,.2);
        }
      }
    }
  }
}