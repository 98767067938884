@import '../../../css/styles/colors';
@import '../../../css/styles/variables';

.center-div-outer {
  .center-div-middle{
    > :first-child.confirm-modal-main{
      padding: 0px;
    }
  }
}

.confirm-modal {
  position: fixed;
  overflow: hidden;
  bottom: 0px;
  left: 0px;
  z-index: 210;
  &.hide-confirm-modal{
    height: 0px;
    width: 0px;
    transition: height 0s, width 0s, padding 0s, overflow 0s;
    transition-delay: 0.35s;
    .confirm-modal-gray-screen{
      opacity: 0;
    }
    :first-child.confirm-modal-main{
      overflow: hidden;
    }
  }
  &.show-confirm-modal {
    height: 100%;
    width: 100%;
    .confirm-modal-gray-screen{
      opacity: 1;
    }
  }
  .confirm-modal-header{
    padding: 8px;
    // margin: 0 0 20px;
    background-color: rgba(240,240,240,1);
    box-shadow: 0px 1px 3px 0px rgba(0,0,0,.2);
    h3{
      margin: 0;
    }
  }
  .confirm-modal-buttons{
    padding: 8px;
    // margin: 20px 0 0;
    background-color: rgba(240,240,240,1);
    box-shadow: 0px -1px 3px 0px rgba(0,0,0,.2);
    display: flex;
    justify-content: space-between;
    .button{
      width: auto;
    }
  }
  

  .confirm-modal-gray-screen{
    position: absolute;
    z-index: 205;
    width: 100%;
    height: 100%;
    background: $gray-screen-background-color;
    opacity: 1;
    transition: opacity 0.35s;
  }

  .confirm-modal-main {
    border-radius: 3px;
    position: relative;
    background: white;
    z-index: 208;
    overflow: hidden;
    .confirm-modal-close-button {
      height: 40px;
      width: 40px;
      top: 0px;
      right: 0px;
      position: absolute;
      border-radius: 2px;
      padding: 4px;
      cursor: pointer;
      svg{
        height: 100%;
        width: 100%;
        .real-path{
          fill: $primary-color;
        }
      }
      z-index: 150;
      &:hover{
        box-shadow: inset 0px 0px 250px 0px rgba(0,0,0,.1);
      }
      &:active{
        box-shadow: inset 0px 0px 250px 0px rgba(0,0,0,.2);
      }
    }
    .confirm-modal-content{
      position: relative;
      max-height: calc(90vh - 48px);
      padding: 20px;
      @media screen and (max-width: $mobile-width) {
        max-height: 80vh;
      }
      overflow: auto;
    }
  }
}
  

@media screen and (max-width: $mobile-width) {
  .confirm-modal{
    &.hide-confirm-modal{
      .confirm-modal-main:first-child{
        transform: scale(0.9);
        margin-top: 100vh;
        transition: margin-top 0.20s $ios-present-cubic-bezier,
                    transform 0.20s $ios-present-cubic-bezier;
      }
    }
    &.show-confirm-modal{
      .confirm-modal-main:first-child{
        transform: scale(1);
        margin-top: 0vh;
        transition: margin-top 0.20s $ios-present-cubic-bezier,
                    transform 0.20s $ios-present-cubic-bezier;
      }
    }
  }
}

@media screen and (min-width: $desktop-width) {
  .confirm-modal{
    &.hide-confirm-modal{
      .confirm-modal-main{
        opacity: 0;
        transform: scale(0.8);
        transition: transform 0.12s $cubic-bezier-default,//ease-out, 
                    opacity 0.12s $cubic-bezier-default;//ease-out;
      }
    }
    &.show-confirm-modal {
      .confirm-modal-main{
        opacity: 1;
        transform: scale(1);
        transition: transform 0.20s $cubic-bezier-default,//ease-out, 
                    opacity 0.20s $cubic-bezier-default;//ease-out;
      }
    }
  }
}

.sandbox .confirm-modal .confirm-modal-main .confirm-modal-close-button svg .real-path{fill:$sandbox-primary-color;}
