

.subscribed-comp{
  margin: 60px auto;
  background-color: #FFF;
  padding: 20px;
  max-width: 400px;
  border-radius: 8px;
  box-shadow: 0px 3px 8px 0px rgba(0,0,0,.2);
  .bold{
    font-weight: 800;
    
  }
}