

.faq-comp{
  position: relative;
  width: 100%;
  max-width: 720px;
  margin: 0 auto;
  .faq-header{
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 0 20px;
    h2{
      display: block;
      color: #333;
      margin: 0 20px;
      width: auto;
    }
    .line{
      flex: 1;
      height: 1px;
      background-color: rgba(100,100,100,1);
    }
  }
  .faq-list{
    padding: 20px;
    .item{
      margin: 0 0 20px;
      &:last-of-type{margin-bottom: 0px;}
      display: flex;
      .faq-icon{

        padding: 4px 0 0 0;
        cursor: pointer;
        height: 40px;
        width: 40px;
        margin-right: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 5px;
        > div{
          display: block;
        }
        &:hover{box-shadow: inset 0px 0px 250px 0px rgba(0,0,0,.15);}
        &:active{box-shadow: inset 0px 0px 250px 0px rgba(0,0,0,.3);}
      }
      .faq-text{
        margin: 20px 0;
        flex: 1;
        .question{
          line-height: 40px;
          font-size: 18px;
          font-weight: 800;
        }
        .answer{
          margin: 12px 0 0 0;
          font-size: 18px;
          color: #555;
        }
      }
    }
  }
}