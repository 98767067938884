

@import '../../../css//styles/colors';

$borderColor: rgba(230,230,230,1);

.stripe-comp{
  margin: 20px 0 0px;
  .payment-details{
    margin: 0 0 12px;
    padding: 0 0 12px;
    .payment-method{
      display: flex;
      justify-content: space-between;
      .card-info{
        flex: 1;
      }
      .buttons{
        .button{
          height: 32px;
          line-height: 32px;
          &:hover{box-shadow: inset 0px 0px 250px 0px rgba(0,0,0,0.15);}
          &:active{box-shadow: inset 0px 0px 250px 0px rgba(0,0,0,0.3);}
        }
      }
    }
  }

  .plan-summary{
    .plan{
      padding: 12px 0;
      margin: 0 0 12px;
      border: 1px solid $borderColor;
      border-width: 1px 0;
      display: flex;
      .image{
        overflow: hidden;
        border-radius: 3px;
        // background-color: $primary-color;
        background-color: #FFF;
        border: 3px solid $primary-color;
        // padding: 3px;
        width: 48px;
        height: 48px;
        svg{
          height: 100%;
          width: 100%;
        }
        .real-path{
          fill: $primary-color;
          // fill: #FFF;
        }

      }
      .details{
        flex: 1;
        margin: 0 12px;
        .plan-name{
          font-size: 20px;
          margin: 0 0 4px;
        }
        .savings{
          font-size: 12px;
          &.green{
            color: #61bd4f;
          }
          &.red{
            color: #eb5a46;
          }
        }
        
      }
      .amount{
        font-size: 14px;
        .strike{
          position: relative;
          opacity:0.8;
          &:after{
            content: ' ';
            font-size: inherit;
            display: block;
            position: absolute;
            right: 0;
            left: 0;
            top: 38%;
            bottom: 38%;
            border-top: 1px solid #000;
            border-bottom: 1px solid #000;
          }
        }
      }
      
    }
    .total{
      margin: 0 0 12px;
      display: flex;
      justify-content: space-between;
      .text{
        font-weight: 700;
      }
      .amount{
        font-weight: 700;
      }
    }
  }

}