@import '../../css/styles/colors';
@import '../../css/styles/variables';


.navi-main{
  -webkit-overflow-scrolling: touch;
  overflow: auto;
  position: relative;
  display: flex;
  flex-flow: column;
  background-color: $primary-color;
  height: 100%;
  min-width: $navi-width;
  @media screen and (min-width: $desktop-width) {
    &.toggle-width-active{
      min-width: $navi-mini-width;
    }
  }

  .width-toggle{
    display: none;
    top: 0px;
    left: $navi-width;
    position: fixed;
    z-index: 25;
    height: 100%;
    width: 4px;
    cursor: pointer;
    &:hover{
      background-color: #FFF;
      box-shadow: 0px 0px 3px 0px rgba(0,0,0,.5);
    }
  }
  &.toggle-width-active .width-toggle{
    left: $navi-mini-width - 2px;
  }
  @media screen and (min-width: $desktop-width) {
    .width-toggle{
      display: block;
    }
  }
}
.sandbox .navi-main{background-color: $sandbox-primary-color;}
@media screen and (max-width: $mobile-width) {
  .navi-main{
    padding-top: 48px;
    padding-bottom: 160px;
    position: absolute;
    top: 0px;
    overflow: auto;
    z-index: 100;
    height: 100vh;
    min-height: calc(100vh - calc(100vh - 100%));
    left: -1 * $navi-width;
    transition: left 0.3s;
    &.open{
      left: 00px;
    }
  }
}

.toggle-width-active .display-item{
  display: flex;
  @media screen and (max-width: $mobile-width) {
    display: none;
  }
  justify-content: center;
  align-items: center;
  height: 44px;
  z-index: 100;
  position: fixed;
  background-color: $primary-color;
  border-radius: 3px;
  box-shadow: 0px 0px 8px 0px rgba(0,0,0,.15); 
  opacity: 1;
  transform: scale(1);
  padding: 4px 12px;
  left:$navi-mini-width + 18px;
  transition: 
      top 0.2s cubic-bezier(0.66, 0.32, 0.28, 0.6),
      left 0.2s linear,
      opacity 0.1s linear,
      transform 0.1s linear;
  &.hide{
    transition: 
      top 0.2s cubic-bezier(0.66, 0.32, 0.28, 0.6),
      left 0.2s linear,
      opacity 0.1s linear,
      transform 0.05s linear;
    transition-delay: 0.1s;
    left: $navi-mini-width + 30px;
    opacity: 0;
    transform: scale(0);
  }
  .detail{
    position: relative;
    flex: 1;
    display: flex;
    flex-flow: column;
    .display,.desc{
      margin: 0px;
      flex: 2;
      color: #FFF;
      font-size: 16px;
      line-height: 20px;
    }
    .desc{
      flex: 1;
      font-size: 10px;
      line-height: 10px;
    }
  }
  
  &::before{
    padding: 0px;
    position: absolute;
    top: 12px;
    left: -20px;
    display: block;
    content:"h";
    font-size: 0px;
    border-top: 10px solid transparent;
    border-right: 10px solid $primary-color;
    border-left: 10px solid transparent;
    border-bottom: 10px solid transparent;
  }
}
.sandbox .toggle-width-active .display-item{background-color: $sandbox-primary-color;}
.sandbox .toggle-width-active .display-item::before{border-right-color: $sandbox-primary-color;}


// Top Navi & desktop
@media screen and (min-width: $desktop-width) {
  .navi-main.top-navi{
    margin: 0 auto;
    height: auto;
    max-width: $container-max-width;
    .width-toggle{display:none;}
    flex-flow: row;
    justify-content: space-between;
    align-items: center;
  }
}