@import '../../../css/styles/colors';
@import '../../../css/styles/variables';

.carousel{
  position: relative;
  overflow: hidden;  
  width: 100%;
  transition:height 0.5s $cubic-bezier-default;

  .carousel-container{
    position: relative;
    display: flex;
    align-items:flex-start;
    align-content:flex-start;
    transition: left 0.5s $cubic-bezier-default;
    & > *{
      height: auto;
      float: left;
      width: 100%;
      opacity: 0;
      transition: opacity 0.5s linear;
      overflow-y: hidden;
      &.active{
        opacity: 1;
      }
    }
  }
  .side-slider{
    position: absolute;
    width: 40px;
    height: 100%;
    background-color: rgba(0,0,0,.15);
    z-index: 100;
    // top: 0px;
    opacity: 1;
    transition: opacity 0.3s;
    cursor: pointer;
    // &.prev{left: 0px;}
    // &.next{right: 0px;}
    &.fade{opacity: 0;}
    &:hover{background-color: rgba(0,0,0,.3);}
    &:active{background-color: rgba(0,0,0,.45);}

    display: flex;
    justify-content: center;
    align-items: center;
    > svg{
      .real-path{
        fill:#FFF;
      }
    }

  }
}


