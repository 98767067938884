

.welcome-comp{
  padding:20px;
  background-color: #FFF;
  margin: 60px auto;
  max-width: 520px;
  border-radius: 3px;
  ol{
    padding-left: 20px;
  }
  .video{
    margin-bottom: 20px;
    position: relative;
    padding-bottom: 55%;
  }
  iframe{
    top: 0;
    left: 0;
    position: absolute;
    width: 100%;
    height: 100%;
  }
}