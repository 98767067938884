@import '../../../css/styles/colors';
@import '../../../css/styles/variables';

.pricing-comp{
  padding: 40px;
  max-width: 920px;
  @media screen and (max-width: $mobile-width) {
    padding: 20px;
  }
  .pricing-options{
    max-width: 660px;
  }

  margin: 0 auto;

  .pricing-options{
    margin: 40px auto;
    display: flex;
    @media screen and (max-width: $mobile-width) {
      flex-flow: column;
    }
    .pricing-option{
      position: relative;
      opacity: .5;
      border-radius: 24px;      
      margin: 8px;
      padding: 20px;
      padding-bottom: 160px;
      flex: 1;
      background-color: #FFF;
      border: 2px solid rgba(0,0,0,.5);
      cursor: pointer;
      &.current-plan{      
        @media screen and (max-width: $mobile-width) {
          margin-bottom: 48px;
        }  
        .current-plan-div{
          overflow: hidden;
          bottom: -40px;
          left: 0px;
          position: absolute;
          background-color: $primary-color;
          height: 60px;
          width: 100%;
          border-radius: 0px 0px 24px 24px;
          color: #FFF;
          text-align: center;
          padding: 20px 0 0 0;
          line-height: 40px;
          .current-plan-div-inner{
            top: 0px;left: 0px;
            position: absolute;
            height: 20px;width: 100%;
            border-radius: inherit;
            background-color: #FFF;
            box-shadow: 0px 3px 8px 0px rgba(0,0,0,.2);          
          }
          .text{
            display: flex;
            justify-content: center;
            align-items: center;
            .loading{
              margin-top: 4px;
            }
          }
        }
      }
      &:hover,
      &.active{
        opacity: 1;
        border-color: $primary-color;
      }
      .banner{
        left: 0px;
        width: 100%;
        top: -14px;
        height: 28px;
        line-height: 24px;
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        > span{
          border: 2px solid $primary-color;
          padding: 0 12px;
          border-radius: 24px;
          display: block;
          background-color: #FFF;
          font-weight: 800;
          color: $primary-color;
        }
      }
      .title{
        margin: 12px 0;
        font-size: 24px;
        color: $primary-color;
        font-weight: 800;
        text-align: center;
      }
      .features{
        margin: 12px 0;
        .feature{
          margin: 8px 0 8px 12px;
          font-size: 15px;
          position: relative;
          &:before{
            left: -12px;
            position: absolute;
            content:"-";
          }
          .bold{
            font-weight: 800;
            text-decoration: underline;
          }
        }
      }
      .price{
        position: absolute;
        width: 100%;
        bottom: 60px;
        left: 0px;
        margin: 0 0 20px;
        .price-container{
          display: flex;
          justify-content: center;
          .symbol,.cents{
            line-height: 30px;
          }
          .dollar{
            margin: 0 8px;
            font-size: 36px;
          }
        }
        .per-month{
          text-align: center;
        }
      }
      .buttons{
        width: 100%;
        padding: 0 20px;
        position: absolute;
        bottom: 20px;
        left: 0px;
      }
    }
  }

  .center-div-outer .center-div-middle > :first-child.checkout-modal {
    max-width: 520px;
  }
  .checkout-modal{
    .checkout-modal-inner{
      .checkout-modal-header{
        display: flex;
        justify-content: space-between;
        margin-right: 20px;
        @media screen and (max-width: 520px) {
          margin-right: 0px;
          flex-flow: column;
        }
        .checkout-modal-billing-rate-container{
          
        }
      }
      .items{
        margin: 36px 0 24px;
        padding: 12px 0;
        border: 1px solid rgba(200,200,200,1);
        border-width: 1px 0;
        .item{
          border: 1px solid rgba(200,200,200,1);
          border-width: 0 0 1px;
          padding: 0 0 12px;
          margin: 0 0 12px;
          display: flex;
          justify-content: space-between;
          .left{
            display: flex;
            .icon{
              margin-right: 12px;
              background-color: $primary-color;
              width: 60px;
              height: 60px;
              display: flex;
              justify-content: center;
              align-items: center;
              border-radius: 8px;
              padding: 8px;
              img{
                width: 100%;
                height: 100%;
              }
            }
            .item-text{
              .product-name{
                font-size: 18px;
                margin-bottom: 4px;
                font-weight: 800;
              }
              .billing-rate-text{
                font-size: 13px;
                margin-bottom: 4px;
                color: #555;
                font-weight: 800;
              }
              .easy-cancel{
                font-weight: 300;
                font-size: 12px;
              }
            }
          }
          .right{

          }
        }
        .total{
          display: flex;
          justify-content: space-between;
        }
      }
      
    }
  }

  .payment-options{
    display: flex;
    .payment-option{
      flex: 1;
      border: 1px solid black;
      text-align: center;
      height: 40px;
      line-height: 40px;
      margin: 0 8px;
      border-radius: 5px;
      cursor: pointer;
      border: 1px solid rgba(200,200,200,1);
      // transition: background-color 0.1s, color  0.1s, border-color 0.3s;
      &.active,&:hover{
        border-color: $primary-color;
        background-color: $primary-color;
        color: #FFF;
      }
      &:first-of-type{margin-left: 0px;}
      &:last-of-type{margin-right: 0px;}
    }
  }

  .sign-in-div{
    .header-text{
      margin: 20px 0;
      text-align: center;
    }
    .buttons{
      display: flex;
      align-items: center;
      justify-content: center;
      a{
        padding: 0px;
        width: auto;
        display: flex;
        text-decoration: none;
        padding-right: 12px;
        margin: 0 12px;
        .icon{
          height: 40px;
          width: 40px;
          padding: 8px;
          svg{
            height: 100%;
            width: 100%;
            .real-path{
              fill: #FFF;
            }
          }
        }
        .text{
          padding: 0 12px;
        }
      }
    }
  }
}