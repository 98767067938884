.Toastify{
  a{
    color: #FFF;
    font-weight: 700;
  }
  .Toastify__toast-container{
    top: 56px;
    right: 8px;
    padding: 0;
    .Toastify__toast{
      overflow: hidden;
      border-radius: 5px;
      padding: 8px;
      min-height: 20px;
      margin-bottom: 8px;
      .Toastify__toast-body{
        padding: 0px;
        margin: 0px;
      }
    }
  }
}


