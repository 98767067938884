.totp-component{
  margin: 60px 0;
  .text{
    display: block;
    margin: 0 0 32px;
  }
  input{
    margin: 0;
  }
  .hint{
    display: block;
    font-size: 12px;
    color: rgba(0,0,0,.6);
    margin: 4px 0 12px;
  }
  a{
    display: block;
    margin: 20px 0;
  }
}