@import '../../../css/styles/variables';

.feedback-comp{
  margin: 0 auto;
  max-width: 480px;
  padding: $default-component-padding;  
  
  .feedback-content{
    margin: 40px 0;
    border-radius: 3px;
    background-color: #FFF;
    padding: 20px;
    @media screen and (max-width: $mobile-width) {
      padding: 12px;
    }
    &:first-of-type{
      margin-top: 0;
    }
    textarea{
      min-height: 120px;
      max-height: 360px;
    }
  }
}