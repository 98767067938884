.api-key-item-comp{
  margin: 0 0 24px;
  &:last-of-type{ margin-bottom: 0px;}
  display: flex;
  .details{
    padding: 12px 0;
    margin: 0 12px 0 0;
    flex: 1;
    .one{
      display: flex;
      justify-content: space-between;
    }
    .two{
    }
  }
  .buttons{
    display: flex;
    flex-flow: column;
    .button-element{
      margin: 0 0 12px;
      &:last-of-type{margin-bottom: 0;}
      width: 40px;
      button{
        font-size: 0px;
        color: rgba(0,0,0,0);
      }
    }
    
  }
}