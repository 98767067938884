.totp-reset-component{
  margin: 60px 0;
  > div {
    margin: 0 0 12px;
    &:last-of-type{margin-bottom: 0;}
    &.items{
      padding: 0 0 0 20px;
      margin: 0 0 12px;
      .item{
        position: relative;
        margin: 0 0 8px;
        &::before{
          text-align: center;
          height: 20px;
          width: 20px;
          left: -20px;
          position: absolute;
          content:"•";
        }
      }
    }
    &.faint{
      font-size: 14px;
      color: rgba(0,0,0,.6);
    }
  }
}