.date-epoch-input{
  display: flex;
  flex: 1;
  margin: 0 8px 0 0;
  .input-div{
    flex: 1;
    position: relative;
    height: 40px;
    margin: 0 8px 0 0;
    .active{
      position: absolute;
      z-index: 10;
      top: 0px;
      height: 30px;
    }
    .sub{
      position: absolute;
      z-index: 10;
      font-size: 10px;
      line-height: 11px;
      bottom: 0px;
      height: 11px;
      margin: 0;
    }
    .active:hover,
    .active:focus,
    .sub:hover,
    .sub:focus{
      z-index: 15;
    }
  }
  .toggle-div{
    height: 40px;
    width: 40px;
    padding: 4px;
    border: 1px solid rgba(220,220,220,1);
    background-color: #FFF;
    border-radius: 3px;
    cursor: pointer;
    &:hover{border-color:rgba(200,200,200,1); box-shadow: inset 0 0 250px 0 rgba(0,0,0,.15);}
    &:active{border-color:rgba(180,180,180,1); box-shadow: inset 0 0 250px 0 rgba(0,0,0,.3);}
    svg{
      height: 100%;
      width: 100%;
    }
  }
}