
// Color palet
$one: #22aaa1;
$two: #f2af29;
$light-gray: #e4edf6;
$dark-gray: #4d5061;
$white: #fffffb;
$black: #30323d;

// $primary-color: #23577a;
// $primary-color: #1d6998;
// $primary-color: #1d8a99;
// $primary-color: #167bb6;
// $primary-color: #7B1E7A;
$primary-color: #BA1200;

// Sandbox
$sandbox-primary-color: #22aaa1;

$base-font-color: rgba(40,40,40,1);

$secondary-color: #4d5061;
$accent-color: #f2af29;

$background-color: $light-gray;
// $background-color: #FFF;

$light-gray-color: rgba(200,200,200,1);
$gray-screen-background-color: rgba(0,0,0,.65);

$footer-background-color: $primary-color;
$sandbox-footer-background-color: $sandbox-primary-color;
$footer-font-color: #FFF;
$footer-font-secondary-color: rgba(255,255,255,.85);





$code-color-blue: #304C89;
$code-color-red: #BA1200;
$code-color-pink: #CA9CE1;
$code-color-orange: #FE5F00;
$code-color-green: #00A676;
$code-color-cyan: #5BC0EB;
$code-color-purple: #712F79;
$code-color-yellow: #FDE74C;


