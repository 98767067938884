@import '../../../css/styles/variables';

.support-comp{
  margin: 0 auto;
  max-width: 800px;
  padding: $default-component-padding;  

  .support-header{
    display: flex;
    justify-content: space-between;
    h1{
      margin: 0;
    }
    .button-element {
      height: 32px;
      width: auto;
      .button-status-image{
        top: 4px;
        right: 4px;
      }
      button{
        height: inherit;
        line-height: 32px;
        padding-right: 32px;
        padding-left: 12px;
      }
    }
  }
  .support-modal-container{
    .block{
      margin: 0 0 12px;
      textarea,input{
        margin: 0;
      }
      textarea{
        min-height: 120px;
        max-height: 360px;
      }
    }
  }
  .support-tickets{
    margin: 40px 0;
    .no-tickets{
      margin:0;
      text-align: center;
      border-radius: 3px;
      background-color: #FFF;
      padding: 20px;
    }
    .ticket{
      color: rgba(20,20,20,1);
      &.gray{ color: rgba(150,150,150,1);}
      text-decoration: none;
      display: block;
      margin: 20px 0;
      border-radius: 3px;
      background-color: #FFF;
      padding: 20px;
      &:first-of-type{margin-top: 0px;}
      &:last-of-type{margin-bottom: 0px;}
      &:hover{ box-shadow: 0px 3px 8px 0px rgba(0,0,0,.15);}
      &:active{ box-shadow: 0px 3px 8px 0px rgba(0,0,0,.30);}
      .ticket-header{
        margin: 0 0 12px;
        display: flex;
        justify-content: space-between;
      }
      .ticket-content{
        display: flex;
        justify-content: space-between;
        > div{
          overflow: hidden;
          text-overflow: ellipsis;
          &.date{
            text-align: right;
            min-width: 220px;
          }
        }
      }
    }
  }
  .please-log-in{
    margin: 20px 0;
    > div{
      margin: 0 0 8px;
    }
  }
}

.support-comp{
  .modal :first-child.support-modal{
    overflow: auto;
    max-width: 480px;
  }
}