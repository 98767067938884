@import './colors'; 
@import './variables'; 

@import './buttons';
@import './input';
@import './text';
@import './highlight-syntax.scss';

*{
  margin: 0;
  padding: 0;
  box-sizing:border-box; 
  -webkit-box-sizing:border-box;
  -moz-box-sizing:border-box; 
  -webkit-overflow-scrolling: touch;
}
html,
body {
  height: 100%;
  margin: 0;
  font-family:  "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: $background-color;
  color: $base-font-color;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",monospace;
}
#root{
  position: relative;
  height: 100%;
}
.app{
  height: 100%;
  display: flex;
  -webkit-overflow-scrolling: touch;
  @media screen and (min-width: $desktop-width) {
    &.top-navi{
      flex-flow: column;
    }
  }
}
@media screen and (max-width: $mobile-width) {
  .app{
    flex-direction: column;
  }
}

#content-container{
  position: relative;
  flex: 1 1 auto;
  display: flex;
  flex-flow: column;
  #content{
    flex: 1;
  }
}

@media screen and (max-width: $mobile-width) {
  #content-container{
    #content{
      padding: 48px 0 0 0;
    }
  }
}

@media screen and (min-width: $desktop-width) {
  .app.sticky-navi #content-container{
    overflow: auto;
  }
}


.loading-item-flow{
  border-radius: 3px;
  background-color: gray;
  @extend .animated-background;
}

.animated-background {
  animation-duration: 1.25s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: #F6F6F6;
  background: linear-gradient(to right, #F6F6F6 8%, #F0F0F0 18%, #F6F6F6 33%);
  background-size: 800px 104px;
  height: 96px;
  position: relative;
}
@keyframes placeHolderShimmer{
  0%{
      background-position: -468px 0
  }
  100%{
      background-position: 468px 0
  }
}
.pointer{
  cursor: pointer;
}

