.hljs-comment,
.hljs-quote {
	color: #7e7887;
}

.hljs-variable,
.hljs-template-variable,
.hljs-attribute,
.hljs-regexp,
.hljs-link,
.hljs-tag,
.hljs-name,
.hljs-selector-id,
.hljs-selector-class {
	color: #be4678;
  color: rgba(102,216,238,1);
}

.hljs-number,
.hljs-meta,
.hljs-built_in,
.hljs-builtin-name,
.hljs-literal,
.hljs-type,
.hljs-params {
	color: #aa573c;
  color: rgba(171,128,254,1);
}

.hljs-string,
.hljs-symbol,
.hljs-bullet {
	color: #2a9292;
  color: rgba(230,219,115,1)
}

.hljs-title,
.hljs-section {
	color: #576ddb
}

.hljs-keyword,
.hljs-selector-tag {
	color: #955ae7
}

.hljs-deletion,
.hljs-addition {
	color: #19171c;
	display: inline-block;
	width: 100%
}

.hljs-deletion {
	background-color: #be4678;
}

.hljs-addition {
	background-color: #2a9292
}

.hljs {
	display: block;
	overflow-x: auto;
  background: #030d1f;
	color: #8b8792;
  color: #DDD;
	padding: 8px;
  border-radius: 5px;
  font-size: 12px;
}

.hljs-emphasis {
	font-style: italic
}

.hljs-strong {
	font-weight: bold
}