@import '../../../css/styles/variables';
@import '../../../css/styles/colors';

$spaceing: 120px; 

.ticket-comp{
  // background-color: #FFF;
  max-width: 800px;
  margin: 0px auto;
  padding: $default-component-padding;  

  .ticket-messages{
    background-color: #FFF;
    padding: 20px;
    // border: 1px solid rgba(220,220,220,1);
    border-radius: 3px;
    .ticket-message{
      margin: 12px 0;
      border-radius: 3px;
      // padding: 20px;
      &.user{margin-left:$spaceing}
      &.staff{margin-right:$spaceing}
      .ticket-user,
      .ticket-date{
        display: flex;
        font-size: 13px;
        line-height: 12px;
        margin: 0 0 2px;
      }
      &.user{
        .ticket-user,
        .ticket-date{
          justify-content: flex-end;
        }
      }
      .ticket-text{
        background-color: rgba(250,176,51,.15);
        border: 1px solid rgba(250,176,51,1);
        margin: 0;
      }
      &.staff{
        .ticket-text{
          background-color: rgba($primary-color,.15);
          border-color: $primary-color;
        }
      }
      
    }
  }
  .ticket-reply{
    margin: 20px 0;
    border-radius: 3px;
    background-color: #FFF;
    padding: 20px;
    form{
      display: flex;
      textarea{
        flex: 1;
        width: auto;
        margin: 0;
        min-height: 40px;
        max-height: 360px;
      }
      .button-element{
        width: auto;
        margin-left: 20px;
      }
    }
    
  }
}