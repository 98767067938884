@import '../../../css/styles/colors';
@import '../../../css/styles/variables';

$guide-width: 240px;
$guide-margin: 32px;

.how-to-use-comp{
  margin: 20px auto;
  max-width: 920px;
  padding: 20px;

  .header-div{
    padding-left: $guide-width + $guide-margin;
    @media screen and (max-width: $mobile-width) {
      padding-left: 0px;
    }
    margin-bottom: 40px;
  }

  .content-div{
    position: relative;
    display: flex;
    @media screen and (max-width: $mobile-width) {
      flex-flow: column;
    }
    .guide{
      top: 60px;
      position: sticky;
      @media screen and (max-width: $mobile-width) {
        position: relative;
        top: 0px;
        margin-bottom: 20px;
      }
      display: flex;
      flex-flow: column;
      margin-right: $guide-margin;
      min-width: $guide-width;
      a{
        margin: 0 0 12px;
        border-radius: 3px;
        padding: 8px 20px;
        background-color: #FFF;
        display: block;
        &:hover,
        &.active{
          text-decoration: none;
          color: #FFF;
          background-color: $primary-color;
        }
      }
    }
    .blocks{
      padding-right: 40px;
      width: 100%;
      .block{
        margin: 0px 0px 20px 0px;
        border-radius: 3px;
        padding: 20px;
        background-color: #FFF;
      }
    }
  }
  ol{
    margin: 20px 0 20px 0;
    padding-left: 20px;    
  }
  .video{
    position: relative;
    padding-bottom: 55%;
  }
  iframe{
    top: 0;
    left: 0;
    position: absolute;
    width: 100%;
    height: 100%;
  }
  .note{
    font-size: 12px;
  }
}