$width-toggle: 520px;


.payment-details{
  .payment-methods-list{
    border: 1px solid rgba(200,200,200,1);
    border-width: 1px 0 0 0;
    padding-top: 12px;
    li{
      border: 1px solid rgba(200,200,200,1);
      border-width: 0 0 1px;
      display: flex;
      justify-content: space-between;
      @media screen and (max-width: $width-toggle) {
        flex-direction: column;
      }
      &.no-payment-methods{
        border-width: 0;
      }
      .card-info{
        flex: 1;
        margin: 0 0 12px;
        .card-name{
          margin: 0 0 4px;
        }
        .card-digits{
          margin: 0 0 4px;
          display: flex;
        }
        .card-expiration{
          display: flex;
        }
      }
      .card-options{
        position: relative;
        width: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        .button-element {
          width: auto;
          margin: 0 0 0 12px;
          @media screen and (max-width: $width-toggle) {
            margin: 0 12px 12px;
          }
          height: 32px;
          &.default-button{
            &.default {
              .background,
              button{
                opacity: 1;
                cursor: default;
              }
            }
          }
          button{
            padding: 0 8px;
            height: inherit;
            line-height: 32px;
          } 
          &.delete{
            button{
              padding-right: 28px;
            }
          }
          .button-status-image{
            top: 4px; right: 4px;
            width: 24px;
            height: 24px;
          }
        }
      }
    }
  }

  .add-payment-method-modal{
    &:first-child{
      max-width: 500px;
    }
  }
}