@import '../../../css/styles/colors';
@import '../../../css/styles/variables';


.dashboard-comp{
  margin: 24px auto;
  max-width: 1040px;
  .search-block .toolbar{
    display: none;
  }
  .dash-carousel-header{
    background-color: #FFF;
    // margin-bottom: 12px;
    margin-bottom: 0px;
    border-radius: 5px 5px 0 0;
    box-shadow: 0px 1px 3px 0px rgba(0,0,0,.25);
    z-index: 50;
  }
  .image-refresh{
    display: flex;
    .button-element{
      height: 32px;
      width: auto;
      button{
        height: 32px;
        line-height: 32px;
        padding-right: 32px;
        padding-left: 8px;
        font-size: 14px;
      }
      .button-status-image{
        top: 4px;
        right: 4px;
      }
    }
    .text{
      flex: 1;
      line-height: 32px;
    }
  }
  .box{
    margin: 20px 0;
    // padding:  20px;
    background-color: #FFF;
    border-radius: 3px;
    &.top-box{
      .top-header{
        h4{
          margin: 0;
        }
        padding: 12px 20px 12px;
        display: flex;
        justify-content: space-between;
        border: 1px solid rgba(200,200,200,1);
        border-width: 0px 0px 1px;
      }
      .left-block{
        display: none;
      }
      .buttons{
        display: flex;
        justify-content: space-between;
        .left,.right{
          display: flex;
          .button-element{
            margin-left: 12px;
            width: auto;
            button{
              padding-right: 40px;
            }
          }
        }
        .left{
          .button-element{
            margin-right: 12px;
            margin-left: 0px;
          }
        }
      }
    }
    &.results,
    &.prompt-queue{
      margin: 0;
      padding: 0px;
      overflow: hidden;
    }
    &.prompt-queue{
      // thead{
      //   display: none;
      // }

      
      .checkbox-scale{
        height: 40px;
        min-width: 0px;
        max-width: 0px;
        display: flex;
        justify-content: center;
        align-items: center;
        opacity: 0;
        transition: all 0.3s;
        box-shadow: 0px 0px 1px 1px $primary-color;
        border-radius: 3px;
        .checkbox-element {
          height: 100%;
          width: 100%;
        }
      }
      .delete-multi-row-on{
        .checkbox-scale{
          min-width: 40px;
          max-width: 40px;
          margin-left: 64px;
          opacity: 1;
        }
      }
      .delete-row-selected{
        border-radius: 3px;
        box-shadow: inset 0px 0px 5px 0px red;
      }

      .prompt-queue-list{
        margin-top: 20px;
        position: relative;
        padding-top: 24px;
        .delete-multi-buttons{
          position: absolute;
          top: 0px;
          right: 20px;
          z-index: 25;
          display: flex;
          .button-element{
            margin-left: 12px;
            width: auto;
            button{
              padding-right: 40px;
            }
          }
        }
        .table-element{
          .search-block,
          .content-block{
            width: calc((100% - 24px));
            margin: 0 12px;
          }
        }
      }
      
      @media screen and (max-width: 720px) {
        .table-element table td:before {
          display: none;
        }
        .td-c{
          flex: 1;
        }
      }

      h4{
        margin: 20px;
      }
      .add-prompt-form{
        border: 1px solid rgba(200,200,200,1);
        border-width: 0px 0px 1px;
        padding: 0 0 20px;
        margin: 20px 20px 8px;
        display: flex;
        @media screen and (max-width: $desktop-width) {
          flex-flow:column;
          .buttons{
            margin: 12px 0 0 0;
            justify-content: flex-end;
          }
        }
        .inputs{
          flex: 1;
        }
        .inputs,.buttons{
          display: flex;
        }
        .generator-button{
          margin: 0 0 0 12px;
          button{
            padding-right: 40px;
          }
        }
      }
      .queued-prompt{
        padding: 4px;
        margin: 0 0 4px;
        display: flex;
        flex-flow: column;
        .button-container{
          display: flex;
          justify-content: space-between;
          height: 0px;
          transition: height 0.3s;
          overflow: hidden;
          &.expand{
            height: 40px;
          }
        }
        // @media screen and (max-width: 720px) {
        //   flex-flow: column;
        // }
        .content{
          flex: 1;
          display: flex;
          margin: 0 0 4px;

          .button-element{
            margin-left: 8px;
            width: auto;
            min-width: 40px;
            max-width: 40px;

            button{
              color: rgba(0,0,0,0);
            }
          }
          select{
            font-size: 14px;
          }
          .repeat{
            display: none;
            position: relative;
            label{
              font-size: 12px;
              z-index: 225;
              line-height: 40px;
              top: 0px;
              left: 8px;
              position: absolute;
            }
            input{
              width: 80px;
              text-align: right;
            }
          }
          textarea{
            font-size: 14px;
            margin: 0 8px;
          }
        }
        
        .buttons{
          display: flex;
          .button-element:not(.save):not(.cancel){
            width: 40px;
            button{
              color: rgba(0,0,0,0);
            }
          }
          .button-element{
            button{
              padding-right: 40px;
            }
          }
          &.right{
            .button-element{margin-right: 12px;}
            .button-element:last-of-type{margin-right: 0px;}
          }
          &.left{
            .button-element{margin-right: 12px;}
            .button-element:last-of-type{margin-right: 0px;}
          }
        }
      }
    }
  }
  .add-prompt-form{
    display: flex;
    select{
      margin: 0 12px 0px 0;
    }
    textarea{
      flex: 1;
      margin-right: 12px;
      margin-bottom: 0px;
    }
    .button-element{
      width: auto;
      input{
        padding-right: 40px;
      }
    }
  }

  .center-div-outer .center-div-middle > :first-child.variation-modal {
    max-width: 720px;
  }
  .variation-modal{
    .variation-top{
      margin: 0 0 20px;
      textarea{
        margin-bottom: 0px;
      }
      .button-element{
        margin-top:20px;
      }
    }
    .variation-bottom{
      position: relative;
      h4{
        margin-bottom: 40px;
      }
      .button-container{
        position: absolute;
        top: 0px;
        right: 0px;
      }
      .button-element{
        width: auto;
        button{
          padding-right: 40px;
        }
      }
      ul{
        li{
          display: flex ;
          align-items: center;
          .text{
            flex: 1;
            font-size: 16px;
            border-radius: 3px;
            padding: 12px;
            border: 1px solid rgba(200,200,200,1);
            box-shadow: 0px 1px 3px 0px rgba(0,0,0,.5);
          }
          .button-element{
            width: auto;
            min-width: 40px;
            max-width: 40px;
            margin-left: 12px;
            button{
              color: rgba(0,0,0,0);
            }
          }
        }
      }
    }

    
  }
  .switch-toggle-box{
    margin: 8px 0;
    display: flex;
    justify-content: center;
  }
  .result-image-div{
    margin: 0 8px;
    display: flex;
    flex-wrap: wrap;
    // img{
    //   

    // }
    .image-item {
      margin: 8px 4px;
      border-radius: 5px;
      box-shadow: 0px 1px 3px 0px rgba(0,0,0,.1);
      overflow: hidden;
      position: relative;
      max-width: 120px;
      max-height: 120px;
      img{
        max-width: 120px;
        max-height: 120px;
      }
      &.zoom-image{
        position: relative;
        overflow: hidden;
        img {
          display: block;
          position: relative;
          width: 200%; 
          height: 200%; 
        }

        &.top-left img { left: 0;top: 0; }
        &.top-right img { left: -100%; top: 0; }
        &.bottom-left img { left: 0; top: -100%; }
        &.bottom-right img { left: -100%; top: -100%;  }
      }
      > span {
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0px;
        left: 0px;
        display: flex;
        justify-content: center;
        align-items: center;
        opacity: 0;
        // display: none;
        > svg{
          height: 40px;
          width: 40px;
          padding: 8px;
          border-radius: 5px;
          background-color: rgba(255,255,255,.8);
          cursor: pointer;
          &:hover{background-color: rgba(255,255,255,.9);}
          &:active{background-color: rgba(255,255,255,1);}
        }
      }
      &:hover > span{
        opacity: 1;
        // display: block;
      }
    }
    
  }

  .modal :first-child.modal-main.image-modal {
    max-width: 640px;
    max-height: calc(100vh - 40px);
    .modal-content{
      padding: 0px;
    }
    .carousel-header{
      overflow-y: hidden;
      margin: 0 0 8px;
      padding-right: 40px;
      .items{
        > div{
          height: 60px;
          max-height: 60px;
        }
      }
    }

    .image-modal-header{
      .header-slide{
        max-height: 60px;
        max-width: 60px;
        padding: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        .aspect-ratio{
          border-radius: 12px;
          height: 40px;
          max-height: 40px;
        }
      }
      .aspect-ratio{
        position: relative;
        width: 100%; 
        overflow: hidden;
        img {
          border-radius: 5px;
          box-shadow: 0px 1px 3px 0px rgba(0,0,0,.1);
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
  
        &.zoom img{
          display: block;
          width: 200%; 
          height: 200%; 
        }
        &.top-left img { left: 0;top: 0; }
        &.top-right img { left: -100%; top: 0; }
        &.bottom-left img { left: 0; top: -100%; }
        &.bottom-right img { left: -100%; top: -100%;  }

        &.aspect-ratio-1-1 {padding-top: 100%; }
        &.aspect-ratio-4-5 {padding-top: 125%; }
        &.aspect-ratio-2-3 {padding-top: 150%; }
        &.aspect-ratio-4-7 {padding-top: 175%; }
        &.aspect-ratio-5-4 {padding-top: 80%; }
        &.aspect-ratio-3-2 {padding-top: 66.67%; }
        &.aspect-ratio-7-4 {padding-top: 57.14%;}
      }
    }

    .slide{
      padding: 12px;
      .aspect-ratio{
        position: relative;
        width: 100%; 
        overflow: hidden;
        img {
          border-radius: 5px;
          box-shadow: 0px 1px 3px 0px rgba(0,0,0,.1);
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          object-fit: cover; 
        }
  
        &.zoom img{
          display: block;
          width: 200%; 
          height: 200%; 
        }
        &.top-left img { left: 0;top: 0; }
        &.top-right img { left: -100%; top: 0; }
        &.bottom-left img { left: 0; top: -100%; }
        &.bottom-right img { left: -100%; top: -100%;  }
      }
      .aspect-ratio-1-1 {padding-top: 100%; }
      .aspect-ratio-4-5 {padding-top: 125%; }
      .aspect-ratio-2-3 {padding-top: 150%; }
      .aspect-ratio-4-7 {padding-top: 175%; }
      .aspect-ratio-5-4 {padding-top: 80%; }
      .aspect-ratio-3-2 {padding-top: 66.67%; }
      .aspect-ratio-7-4 {padding-top: 57.14%;}
    }

    .buttons{
      padding: 0 12px 12px;
      display: flex;
      justify-content: space-between;
      >div{
        display: flex;
      }
    }
  }
  .result-image-div-container{
    .copy-input{
      margin: 0 8px;
      line-height: 24px;
      font-size: 12px;
      input{
        line-height: 12px;
        font-size: 12px;
      }
    }
    
  }
  .button-actions{
    .button-element{
      width: 40px;
      button{
        color: rgba(0,0,0,0);

      }
    }
  }
  .info-slide{
    padding: 0 52px;
  }
}