.sns-button{
  margin: 0 0 20px 0;
  padding: 0 40px;
  position: relative;
  overflow: hidden;
  &:before{
    position: absolute;
    display: block;
    left: 6px;top: 6px;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: 28px;
    content: "";
    height: 28px;
    width: 28px;
    border-radius: 3px;
  }
  a{
    top: 0px;left: 0px;
    display: block;
    position: absolute;
    height: 100%;
    width: 100%;
    text-decoration: none;
    color: #FFF;
    padding: 0 40px;
    white-space: nowrap;
  }

  &.facebook-button{
    background-color: #3b5998;
    &:before{
      background-image: url("../../../css/imgs/sns-icon-facebook.svg");
    }
  }
  &.google-button{
    background-color: #4285F4;
    &:before{
      background-color: #FFF;
      background-image: url("../../../css/imgs/sns-icon-google.svg");
    }
  }

  &.disabled{
    opacity: 0.5;
    cursor: not-allowed;
    .loading{
      position: absolute;
      top: 8px;
      right: 8px;
    }
  }

  &:last-of-type{
    margin: 0;
  }

  &.linked{
    position: relative;
    .email,.unlink{
      top: 0px;
      left: 0px;
      padding: 0 40px;
      width: 100%;
      text-align: center;
      position: absolute;
      transition: opacity 0.3s;
    }
    .email{opacity: 1;}
    .unlink{opacity: 0;}
    &:hover{
      .email{opacity: 0;}
      .unlink{opacity: 1;}
    }
    
  }
}