.copy-input{
  margin: 12px 0;
  display: flex;
  background-color: #fff;
  border: 1px solid rgba(200,200,200,1);
  border-radius: 3px;
  line-height: 40px;
  .display{
    padding: 0 8px;
  }
  input{
    flex: 1;
    border: 0px;
  }
  textarea{
    margin: 0;
    border: 0px;
  }
  .copy{
    cursor: pointer;
    font-size: 0px;
    color: rgba(0,0,0,1);
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover{box-shadow: inset 0px 0px 250px 0px rgba(0,0,0,.15);}
    &:active{box-shadow: inset 0px 0px 250px 0px rgba(0,0,0,.3);}
  }

}