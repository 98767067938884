
@import '../../../css/styles/variables';

.uninstall-comp{
  margin: 60px auto;
  max-width: 520px;
  padding: $default-component-padding;  
  background-color: #FFF;
  border-radius: 3px;
  padding: 20px;

  
  .feedback-content{
    @media screen and (max-width: $mobile-width) {
      padding: 12px;
    }
    &:first-of-type{
      margin-top: 0;
    }
    textarea{
      min-height: 120px;
      max-height: 360px;
    }
  }
}
