.navi-notifications{
  height: 100%;
  overflow: auto;
  .no-new-notifs{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    > span{

    }
  }
  .notif-item{
    position: relative;
    margin: 20px;
    padding: 12px;
    background-color: #FFF;
    box-shadow: 0px 1px 3px 0px rgba(0,0,0,.1);

    .notif-message{
      font-size: 14px;
    }
    .notif-date{
      font-size: 14px;
    }
    &.unseen{
      &::before{
        top: calc(50% - 3px);
        left: -12px;
        height: 6px;
        width: 6px;
        position: absolute;
        content:"";
        background-color: red;
        box-shadow: 0px 1px 3px 0px rgba(0,0,0,.25);
        border-radius: 50%;
      }
    }
  }
  .not-signed-in{
    padding: 0 12px;
    height: 100%;
    flex: 1;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    span{
      margin: 12px 0;
    }
  }
}