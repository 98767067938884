@import '../../../css/styles/colors';
@import '../../../css/styles/variables';

$header-height: 60px;
$button-margin: 8px;

$max-width: 1020px;
.home-comp{
  padding: 40px 0;
  @media screen and (max-width: $mobile-width) {
    padding: 20px 0
  }
  // background-color: #FFF;
  .section{
    h1,h2,p{
      width: 100%;
    }
    &.header1{
      h1{
        line-height: 48px;
        font-size: 32px;
        margin: 0px 0px 8px;
      }
      h4{
        font-weight: 400;
      }
      .text{
        max-width: 360px;
      }
      .avail-div{
        > a{
          margin: 4px 0;
          display: flex;
          .icon{
            flex: 0;
            width: 32px;
            height: 32px;
            display: flex;
            justify-content: center;
            align-items: center;
            svg,img{
              width: 32px;
              height: 32px;
            }
            svg{
              padding: 4px;
            }
          }
          span{
            margin: 0 8px;
            flex: 1;
            display: block;
            line-height: 32px;
          }
        }
      }
      // background-color: blue;
    }
    &.bg{
      background-color: $primary-color;
      color: #FFF;
    }

    .start-gen-btn{
      width: auto;
      height: 60px;
      display: flex;
      flex-flow: column;
      padding: 8px;
      margin: 20px 0 28px;
      border-radius: 5px;
      > div{
        line-height: 28px;
        font-size: 18px;
        font-weight: 500;
        // margin: 0 0 4px;
      }
      > span{
        font-size: 14px;
        line-height: 14px;
        font-weight: 300;
        display: block;
      }
    } 
    // .ca.button{
    //   margin: 16px 0 0;
    //   width: auto;
    //   height: 44px;
    //   line-height: 44px;
    // }

    .inner{
      padding: 20px;
      @media screen and (max-width: $mobile-width) {
        padding: 0px;
      }
      margin: 0 auto;
      max-width: $max-width;
      &.boxer{
        display: flex;
        &.reverse{
          flex-flow: row-reverse;
        }
        @media screen and (max-width: $mobile-width) {
          flex-flow: column;
          &.reverse{
            flex-flow: column;
          }
        }
        .image{
          padding: 20px;
          flex: 15;
          > span{
            display: flex;
            justify-content: center;
            align-items: center;
            svg,img{
              height: auto;
              max-width: 100%;
            }
          }
          &.svg{
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            svg{
              height: 180px;
              width: 180px;
              .real-path{
                fill:$primary-color;
              }
            }
          }
        }
        .spacer{
          min-height: 20px;
          flex: 2;
          @media screen and (max-width: $mobile-width) {
            flex: 0;
            min-height: 0px;
          }
        }
        .text{
          display: flex;
          flex-flow: column;
          justify-content: center;
          padding: 40px;
          flex: 15;
          @media screen and (max-width: $mobile-width) {
            // padding: 20px;
          }
        }
      }
    }
    
    
  }
  .how-it-works-section{
    .text{
      margin: 0 auto;
      max-width: 600px;
      text-align: center;
    }
  }
  .steps-section{
    .text{
      h2{
        text-align: center;
        margin-bottom: 8px;
      }
      h4{
        font-size: 16px;
        font-weight: 300;
        text-align: center;
      }
      .steps{
        display: flex;
        @media screen and (max-width: $mobile-width) {
          flex-flow: column;
        }
        .step{
          position: relative;
          padding: 20px;
          margin: 20px;
          flex: 1;
          background-color: #FFF;
          box-shadow: 0px 3px 8px 0px rgba(0,0,0,.5);
          border-radius: 5px;
          display: flex;
          flex-flow: column;
          .num{
            width: 100%;
            left: 0px;
            top: -20px;
            position: absolute;
            text-align: center;
          }
          .icon{
            margin: 20px 0;
            height: 60px;
            flex: 1;
            display: flex;
            justify-content: center;
            align-items: center;
            svg{
              height: 60px;
              width: 60px;
              flex: 1;
              .real-path{
                fill: $primary-color;
              }
            }
          }
          span{
            height: 40px;
            display: block;
            color: $primary-color;
            text-align: center;
          }
        }
      }
    }
    .how-to-use-note{
      text-align: center;
      font-weight: 300;
      a{
        font-weight: 800;
        color: #FFF;
      }
    }
  }
  .try-it-div{
    display: flex;
    justify-content: center;
    align-items: center;
    .try-it{
      display: block;
      width: auto;
    }
  }

}


