.api-comp{
  .note{
    font-size: 12px;
  }
  .api-keys{
    margin: 24px 0;
    
  }
  .no-api-keys{
    opacity: 0.75;
  }
  .modify-api-key-modal{
    .toggle-box{
      width: 100%;
      display: flex;
      justify-content: space-between;
      margin: 0 0 12px;
      p{margin: 0;}
    }
    .example-ip{
      font-size: 12px;
      display: flex;
      margin: 0 0 12px;
    }
    .totp-div{
      display: flex;
      flex-flow: column;
      justify-content: center;
      align-items: center;
      .icon{
        margin: 40px 0 20px;
        height: 80px;
        width: 80px;
        svg{
          height: 100%;
          width: 100%;
          .real-path{
            fill: rgba(0,0,0,.75);
          }
        }
      }
      .text{
        .header{
          width: 100%;
          text-align: center;
          margin: 0 0 20px;
          font-size: 18px;
          font-weight: 500;
        }
        .sub{
          margin: 0 48px 20px;
          font-size: 16px;
          font-weight: 300;
          text-align: center;
        }
        a{
          display: block;
          width: 160px;
          margin: 40px auto;
          text-align: center;
        }
      }
    }
  }
  .new-api-ley-results{
    .copy-input:last-of-type{
      margin: 0;
    }
  }
}