@import '../../../css/styles/colors';
@import '../../../css/styles/variables';

$delay-spacing: -0.062s;

$width: 12%;
$height: 16%;

.loading {
  position: relative;
  // animation: lds-roller 2.0s $loading-cubic-bezier infinite;
  background-color: rgba(255,255,255,.05);
  box-shadow: 0px 0px 5px 0px rgba(255,255,255,.1);
  border-radius: 50%;
  div {
    position: absolute;
    height: 100%;
    width: 100%;
    animation: lds-roller 1.1s $loading-cubic-bezier infinite;
    &:after {
      content: " ";
      position: absolute;
      width: $width;
      height: $height;
      border-radius: 50%;
      background-color: $primary-color;
      top: 0%;
      left: 50% - ($width/2);
    }
    &:nth-child(1) { animation-delay: $delay-spacing * 1; }
    &:nth-child(2) { animation-delay: $delay-spacing * 2; }
    &:nth-child(3) { animation-delay: $delay-spacing * 3; }
    &:nth-child(4) { animation-delay: $delay-spacing * 4; }
    &:nth-child(5) { animation-delay: $delay-spacing * 5; }
    &:nth-child(6) { animation-delay: $delay-spacing * 6; }
    &:nth-child(7) { animation-delay: $delay-spacing * 7; }
    &:nth-child(8) { animation-delay: $delay-spacing * 8; }
    &:nth-child(9) { animation-delay: $delay-spacing * 9; }
    &:nth-child(10) { animation-delay: $delay-spacing * 10; }
    &:nth-child(11) { animation-delay: $delay-spacing * 11; }
    &:nth-child(12) { animation-delay: $delay-spacing * 12; }
  }
  &.secondary-color div:after{
    background-color: $secondary-color;
  }
  &.accent-color div:after{
    background-color: $accent-color;
  }
  &.white div:after{
    background-color: #FFF;
  }
}

@keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
