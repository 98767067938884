@import '../../../css/styles/colors';
@import '../../../css/styles/variables';

.carousel-header{
  position: relative;
  border: 1px solid $light-gray-color;
  border-width: 0px 0px 1px 0px;
  margin: 24px 0;
  max-width: 100%;
  overflow: auto;
  > .items{
    display: flex;
    > div {
      flex: 1;
      color: #555;
      cursor: pointer;
      height: 40px;
      line-height: 40px;
      text-align: center;
      white-space: nowrap;
      &:hover{ box-shadow: inset 0px 0px 250px 0px rgba(0,0,0,.1);}
      &:active{ box-shadow: inset 0px 0px 250px 0px rgba(0,0,0,.2);}
      &.active{
        cursor: default;
        color: $primary-color;
        &:hover{ box-shadow: inset 0px 0px 250px 0px rgba(0,0,0,0);}
        &:active{ box-shadow: inset 0px 0px 250px 0px rgba(0,0,0,0);}
      }
    }
  }
  .carousel-header-underline-container{
    position: absolute;
    height: 2px;
    bottom: 0;
    width: 100%;
    overflow: hidden;
    .carousel-header-underline{
      position: absolute;
      background-color: $primary-color;
      height: 2px;
      bottom: 0;
      left: 0;
      transition: left 0.3s $cubic-bezier-default, 
                  width 0.3s $cubic-bezier-default;
      width: 20px;
      border-radius: 100px 100px 0 0;
    }
  }
}
.sandbox .carousel-header > .items > div.active{color: $sandbox-primary-color; }
.sandbox .carousel-header .carousel-header-underline-container .carousel-header-underline{background-color: $sandbox-primary-color;}