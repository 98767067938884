@import '../../../css/styles/colors';
@import '../../../css/styles/variables';

.billing-rate-comp{
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  label{
    color: rgba(50,50,50,1);
    font-weight: 800;
    font-size: 12px;
    text-align: center;
  }
  .billing-rate-options{
    display: flex;
    justify-content: center;
    box-shadow: 0px 3px 8px 0px rgba(0,0,0,.2);
    border-radius: 12px;
    width: auto;
    .billing-rate-option{
      position: relative;
      width: 100px;
      cursor: pointer;

      .billing-rate-text{
        height: 40px;
        line-height: 40px;
        text-align: center;
        background-color: #FFF;
        // transition: background-color 0.1s, color  0.1s;
      }
      &:first-of-type .billing-rate-text{
        border-radius: 12px 0 0 12px;
      }
      &:last-of-type .billing-rate-text{
        border-radius: 0 12px 12px 0 ;
      }
      &.active,
      &:hover{
        .billing-rate-text{
          background-color: $primary-color;
          color: #FFF;
        }
      }
      .billing-rate-save{
        position: absolute;
        height: 20px;
        width: 100%;
        bottom: -20px;
        text-align: center;
        font-size: 14px;
        font-weight: 800;
        color: rgba(50,50,50,1);
      }
    }
  }
}