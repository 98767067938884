
@import '../../../css/styles/variables';

.center-div-outer{
  display: table;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
  .center-div-middle{
    display: table-cell;
    vertical-align: middle;
    > :first-child {// center-div-inner
      position: relative;
      border-radius: 3px;
      background-color: #FFF;
      padding: 20px;
      margin: 60px auto;
      max-width: 400px;
    }
    @media screen and (max-width: 400px) {
      > :first-child {// center-div-inner
        max-width: 100vw;
      }
    }
  }
}