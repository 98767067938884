@import '../../css/styles/colors';
@import '../../css/styles/variables';

$height: 48px;
$width: $height;

.navi-mobile{
  display: flex;
  flex: 0 0 0;
  overflow: hidden;
  position: relative;
  height: 0px;
  transition: height 0.3s, flex 3.3s;
  background-color: $primary-color;
  box-shadow: 0px 1px 3px 0px rgba(0,0,0,.1);

  > .button{
    position: absolute;
    top: 0px;
    padding: 0 8px;
    svg{
      height: 100%;
      width: 100%;
      .real-path{
        fill: #FFF;
      }
    }
    &.menu{ left: 0px; }
    &.notifications{ 
      right: 0px; 
      svg{
        padding: 2px;
      }
    }
  }
  > .logo-container{
    flex: 1;
    .navi-logo{
      background-color: rgba(0,0,0,0);
      .text{
        font-size: 20px;
      }
    }
  }

  .notifications .badge{
    text-align: center;
    width: 14px;
    height: 14px;
    line-height: 14px;
    font-size: 12px;
    top: 8px;
    right: 8px;
    position: absolute;
    background-color: red;
    border-radius: 50%;
    color: #FFF;
    box-shadow: 0px 1px 3px 0px rgba(0,0,0,.5);
  }
}
.sandbox .navi-mobile{background-color: $sandbox-primary-color;}
@media screen and (min-width: $desktop-width) {
  .navi-mobile{
    > .button{
      width: 0px;
    }
    > .logo-container{
      width: 0px;
    }
  }
  
}
@media screen and (max-width: $mobile-width) {
  .navi-mobile{
    padding: 0 44px;
    position: fixed;
    z-index: 150;
    height: $height;
    width: 100%;
    flex: 0 0 $height;
    button{
      height: $height;
      width: $width;
    }
  }
}