@import '../../../css/styles/colors';

.payment{
  .card-element{
    border-radius: 3px;
    border: 1px solid rgba(220,220,220,1);;
    margin: 0 0 12px;
    padding: 10px 12px;
    &:hover{
      border-color: rgba(205,205,205,1);
    }
    &.focus{
      border-color: $primary-color;
    }
  }
}