@import '../../../css/styles/colors';


$columnHeight: 24px;

.account-type{
  > label{
    display: flex;
    .upgrade{
      margin: 0 8px;
      cursor: pointer;
      color: $primary-color;
      &:hover{
        text-decoration: underline;
      }
    }
  }
  .general-block{
    display: flex;
    align-items: center;
    height: $columnHeight;
    .value{
      margin: 0 8px;
      a{
        margin: 0 8px;
      }
      &.bolden{
        font-weight: 800;
        text-decoration: underline;
      }
      .big{
        margin-right: 4px;
        font-weight: 800;
        font-size: 20px;
      }
      .small{
        font-size: 12px;
      }
    }
  }
  
  .sub-details{
    margin: 24px 0 0;
    .plan-name,
    .period-end,
    .plan-interval,
    .plan-cost,
    .general{
      display: flex;
      align-items: center;
      height: $columnHeight;
      .value{
        margin: 0 8px;
      }
    }
    .auto-renew{
      margin: 4px 0 0;
      display: flex;
      align-items: center;
    }
  }

  .test-buttons{
    margin: 20px 0;
    display: flex;
    .button-element{
      margin: 0 20px;
      width: auto;
    }
  }
}