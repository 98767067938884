.sign-up{
  .legal{
    margin: 12px 0 24px;
    font-size: 14px;
    display: flex;
    align-items: center;
    .checkbox-element{
      margin: 0 8px 0 0;
    }
  }
  .referral-info{
    padding: 12px;
    margin: 12px 0 0;
    border-radius: 3px;
    background-color: rgba(200,200,200,.5);
    h4{
      margin: 0 0 4px;
    }
    .note{
      font-size: 10px;
      margin: 0;
    }
  }
}