@import '../../../../css/styles/variables';

.terms-of-use{
  margin: 40px;
  padding: $default-component-padding;  
  background-color: #FFF;
  border-radius: 3px;

  ul{
    list-style: disc;
    padding: 0 0 0 20px;
  }
  ol{
    padding: 0 0 0 20px;
  }
}