@import '../../css/styles/colors';
@import '../../css/styles/variables';

$icon-padding: 8px;

.navi-body{
  flex: 1;
  > .list{
    display: flex;
    justify-content: center;
    flex-flow: column;
    margin: 0px;
    > .list-item{
      margin: 0px 8px 8px;
      > a,
      > .logout{
        border-radius: 5px;
        display: flex;
        position: relative;
        color: #FFF;
        padding: 0 0 0 44px;
        height: 44px;
        width: 100%;
        > .icon{
          position: absolute;
          left: 0;
          top:0;
          height: 100%;
          width: 44px;
          padding: 0 $icon-padding;
          svg{
            position: relative;
            height: 100%; 
            width: 100%;
            .real-path{
              fill: #FFF;
              transition: fill 0.3s;
            }
          }
          img{
            border-radius: 50%;
            margin-left: -8px;
            padding: 6px;
            height: 44px;
            width: 44px;
          }
        }
        > .detail{
          flex: 1;
          padding: 6px 0;
          display: flex;
          flex-flow: column;
          .display,
          .desc{
            flex: 2;
            display: flex;
            align-items: center;
          } 
          .desc{
            font-size: 10px;
            flex: 1;
          }
        }
        &:hover, &.active{
          transition: all 0.3s;
          cursor: pointer;
          color: $primary-color;
          background-color: #FFF;
          text-decoration: none;
          > .icon svg .real-path{
            fill: $primary-color;
          }
        }
      }
    }
  }
  .line{
    margin:0 4px 8px;
    background:linear-gradient(to right, $primary-color, rgba(255,255,255,1), $primary-color, );
    width: calc(100% - 8px);
    height: 1px;
  }
}
@media screen and (min-width: $desktop-width) {
  .navi-main.toggle-width-active{
    > .navi-body{
      > .list {
        > .list-item{
          > a,
          > .logout{
            > .detail{
              position: absolute;
              left: 58px;
              opacity: 0;
              transition: left 0.3s,opacity 0.3s;
              border-radius: 3px;
              padding: 6px 12px;
              height: 44px;
              width: auto;
              padding: 0;
              width: 0px;
              height: 0px;
              z-index: 100;
              // background-color: $primary-color;
              color: #FFF;
              box-shadow: 0px 1px 3px 0px rgba(0,0,0,.2);
              white-space: nowrap;
              overflow: hidden;
            }
          }
          &:hover{
            > a,
            > .logout{
              > .detail {
                overflow: visible;
                left: 64px;
                opacity: 1;
                display: flex;
                padding: 6px 12px;
                height: 44px;
                width: auto;
              }
            }
          }
        }
      }
    }
  }
}

// Top Navi & desktop
@media screen and (min-width: $desktop-width) {
  .navi-main.top-navi .navi-body{
    display: flex;
    > .list{
      height: 100%;
      flex-flow: row;
      align-items: center;
      .list-item{
        margin: 0 0 0 8px;
        > a{
          padding-left: 36px;
          padding-right: 8px;
          height: 36px;
          .icon{
            padding: 5px;
            width: 36px;
          }
        }
      }
    }
    .line,.list.user-sign{
      display: none;
    }
  }
}

.sandbox .navi-body{
  > .list > .list-item{
    > a, > .logout{
      &:hover, 
      &.active{
        color: $sandbox-primary-color;
        > .icon svg .real-path{
          fill: $sandbox-primary-color;
        }
      }
    }
  }
  .line{
    background:linear-gradient(to right, $sandbox-primary-color, rgba(255,255,255,1), $sandbox-primary-color, );
  }
}